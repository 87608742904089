import { EApiRoutes, ERoutes, lang } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { IFEvent, IUserInfo } from '../../types'
import { Link, NavigateFunction, useNavigate } from 'react-router'
import texts from '../../configs/texts'
import { prepareUrlWithId } from '../../helpers'

const UserFEventsRow = (props: {
    item: IFEvent
    navigate: NavigateFunction
}) => {
    const { item, } = props

    return (
        <tr>
            <td><Link to={prepareUrlWithId(ERoutes.adminManageFEvent, item.id!)}>{item.id}</Link></td>
            <td>{item.name}</td>
            <td>{item.createdAt?.toString()}</td>
            <td>{(texts[lang] as any)[item.status]}</td>
            <td>{item.rewardAmount}</td>
            <td>{item.updatedAt?.toString()}</td>
        </tr>
    )
}

export const UserFEvents = (props: { user: IUserInfo }) => {
    const [itemsList, itemsListUpdate] = useState<IFEvent[]>(null as any)
    const [filter] = useState<{ user: number, }>({
        'createdBy': props.user.id
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.fevent,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    const navigate = useNavigate()
    // const onSaveSubmit = (item: Partial<IUserInfoFromAdmin>) => {
    //     if (item.confirmPassword && item.confirmPassword !== item.password) {
    //         throw new Error(`Passwords confirmation doesn't match`)
    //     }

    //     requestHelper.request({
    //         method: 'post',
    //         // url: EApiRoutes.adminWalletTranasactions,
    //         data: item,
    //     }).then((createdOrUpdatedItem: IUserInfoFromAdmin) => {
    //         getItemsList()
    //     })
    // }

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>События пользователя</Typography>
            {/* <Input
                placeholder='Filter by username...'
                value={filter.username}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    username: e.target.value
                } : {} as any)}
            /> */}
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>{texts[lang].id}</td>
                        <td>{texts[lang].name}</td>
                        <td>{texts[lang].createdAt}</td>
                        <td>{texts[lang].status}</td>
                        <td>{texts[lang].rewardAmount}</td>
                        <td>{texts[lang].updatedAt}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IFEvent, i: number) => (
                            <UserFEventsRow
                                key={i}
                                item={item}
                                navigate={navigate}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}
