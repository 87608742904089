import React, { useState } from 'react';
import { ETransferTypes, lang } from '../configs/constants';
import { Box, Button, FormControl, Icon, Input, Popper } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import { Select, Option } from '@mui/joy';
import texts from '../configs/texts';
import checkmarkIcon from '../images/checkmark_icon.png'

export interface ITransferRequestData {
    transferType: ETransferTypes
    amount: number,
    userId: number
}

export const TransferPopper = (props: {
    userId: number,
    submitUpdateBalance: (data: ITransferRequestData) => void
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [transferRequest, transferRequestUpdate] = useState<ITransferRequestData>({
        transferType: ETransferTypes.depositRequest,
        amount: 0,
        userId: props.userId,
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    console.log('transferRequest > ', transferRequest)
    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <Icon><Edit></Edit></Icon>
            </button>
            <Popper
                id={id}
                open={open}
                placement='top'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <FormControl
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <Select
                        id='select_transfer_type'
                        placeholder='Transfer type'
                        value={transferRequest.transferType}
                        onChange={(_, val): any => transferRequestUpdate({
                            ...transferRequest,
                            transferType: val as ETransferTypes
                        })}
                    >
                        {
                            Object.values(ETransferTypes).map((val) => {
                                console.log(`transferType val > ${val}`)
                                return <Option key={val} value={val}>{val}</Option>
                            })
                        }
                    </Select>
                    <Input
                        placeholder={texts[lang].amount}
                        value={transferRequest.amount}
                        onChange={(e): any => transferRequestUpdate({
                            ...transferRequest,
                            amount: +e.target.value,
                        })}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '10px',
                    }}>
                        <Button
                            disabled={transferRequest.amount === 0}
                            onClick={() => props.submitUpdateBalance(transferRequest)}>
                            <Save color='success' />
                        </Button>
                    </div>
                </FormControl>
            </Popper>
        </div>
    );
}

export const ChangePasswordPopper = (props: {
    userId: number,
    submitUpdate: (data: { password: string, confirmPassword: string }) => void
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [requestData, requestDataUpdate] = useState<{
        password: string,
        confirmPassword: string
    }>({
        password: '',
        confirmPassword: '',
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <Icon><Edit></Edit></Icon>
            </button>
            <Popper
                id={id}
                open={open}
                placement='top'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <Input
                        placeholder={texts[lang].password}
                        value={requestData.password}
                        onChange={(e): any => requestDataUpdate({
                            ...requestData,
                            password: e.target.value,
                        })}
                    /><br />
                    <Input
                        placeholder={texts[lang].confirmPassword}
                        value={requestData.confirmPassword}
                        onChange={(e): any => requestDataUpdate({
                            ...requestData,
                            confirmPassword: e.target.value,
                        })}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '10px',
                    }}>
                        <Button
                            disabled={!requestData.password.length || requestData.password !== requestData.confirmPassword}
                            onClick={() => props.submitUpdate(requestData)}>
                            <Save color='success' />
                        </Button>
                    </div>
                </Box>
            </Popper>
        </div>
    );
}

export const TransfeConfirmPopper = (props: {
    transferId: number,
    submitUpdate: (transferId: number, data: { paymentDetails: string }) => void
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [requestData, requestDataUpdate] = useState<{
        paymentDetails: string,
    }>({
        paymentDetails: '',
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <img style={{ width: '32px' }} src={checkmarkIcon} alt='confirm' />
            </button>
            <Popper
                id={id}
                open={open}
                placement='top'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <Input
                        placeholder={texts[lang].details}
                        value={requestData.paymentDetails}
                        onChange={(e): any => requestDataUpdate({
                            ...requestData,
                            paymentDetails: e.target.value,
                        })}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '10px',
                    }}>
                        <Button
                            disabled={!requestData.paymentDetails.length}
                            onClick={() => props.submitUpdate(props.transferId, requestData)}>
                            <Save color='success' />
                        </Button>
                    </div>
                </Box>
            </Popper>
        </div>
    );
}
