import { EApiRoutes } from '../../configs/constants'
import { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, FormControl, FormGroup, Input, InputLabel, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { ISettings } from '../../types'
import { Save } from '@mui/icons-material'
// import { generateSitemap } from '../../helpers/sitemap'

// const TransactionsRow = (props: {
//     item: IUserTransaction
//     onTransferSubmit: (transferId: number, data: { paymentDetails: string }) => void
//     navigate: NavigateFunction
// }) => {
//     const { item } = props

//     const [updatedItem, updatedItemUpdate] = useState<Pick<IUserTransaction, 'id' | 'status'>>({
//         id: item.id,
//         status: item.status,
//     });

//     return (
//         <tr>
//             <td>{item.id}</td>
//             <td>{item.user.id}</td>
//             <td>{item.createdAt}</td>
//             <td>{item.updatedAt}</td>
//             <td>{item.amount}</td>
//             <td>{item.balanceBefore}</td>
//             <td>{item.balanceAfter}</td>
//             <td>{item.status}</td>
//             <td>{item.transferType}</td>
//             <td>{JSON.stringify(item.details)}</td>
//             <td>
//                 {
//                     [EWalletTransactionStatuses.pending, EWalletTransactionStatuses.onReview].includes(item.status) &&
//                     <TransfeConfirmPopper transferId={item.id} submitUpdate={props.onTransferSubmit} />
//                     }
//                 {/* <Button
//                     onClick={() => onTransferSubmit(updatedItem)}
//                 ><Save color='success' /></Button> */}
//             </td>
//         </tr>
//     )
// }

export const Settings = () => {
    const [settings, settingsUpdate] = useState<ISettings>(null as any)

    const getSettings = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.config,
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                settingsUpdate(items)
            })
    }

    const updateSettings = () => {
        const { id, ...preparedData } = settings
        const preparedParams: AxiosRequestConfig = {
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminUpdateSettings, id),
            data: preparedData
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                settingsUpdate(items)
            })
    }

    if (!settings) getSettings()

    console.log('settings  > ', settings)

    return settings ? (
        <div>
            <Typography variant='h5'>Настройки</Typography>

            <FormGroup className='form-group'>
                <FormControl>
                    <InputLabel id={`externalCurrency-label`}>Внешняя валюта</InputLabel>
                    <Input
                        placeholder='Основная валюта'
                        value={settings.baseExternalCurrency}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            baseExternalCurrency: e.target.value
                        })}
                    />
                </FormControl>
            </FormGroup>
            <Button
                onClick={updateSettings}
            ><Save color='success' /></Button>
        </div >
    ) : <Loader />
    // return <Loader /> */

    // return (<>
    //     Settings
    //     <Loader />
    // </>)
}
