export const features: { title: string, description: string }[] = [
    // {
    //     title: texts[lang].betOnEvents,
    //     description: texts[lang].betOnEventsDescription
    // },
    // {
    //     title: texts[lang].loyalSystemOfRewards,
    //     description: `${texts[lang].loyalSystemOfRewardsDescription}`
    // },
    // {
    //     title: texts[lang].earnMoneyInRefSystem,
    //     description: `${texts[lang].earnMoneyInRefSystemDescription}`
    // },
    // {
    //     title: texts[lang].initYourOwnEventForBetAndEarnMoney,
    //     description: `${texts[lang].initYourOwnEventForBetAndEarnMoneyDescription}`
    // },
    // {
    //     title: texts[lang].beOneStepAhead,
    //     description: texts[lang].beOneStepAheadDescription
    // },
    // {
    //     title: texts[lang],
    //     description: '',
    // }
]
