import {
    Autocomplete,
    Box, CardContent, Container, FormControl, Input, InputLabel, Paper, Tab,
    Table, TableBody, TableCell, TableContainer, TableRow,
    Tabs, TextField, Typography
} from '@mui/material'
import { Option, Select } from '@mui/joy'
import { Link, useNavigate, useParams } from 'react-router-dom'

import texts from '../../configs/texts'
import { EApiRoutes, EFEventStatuses, ERoutes, lang } from '../../configs/constants'
import React, { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Loader } from '../../components/Loader'
import { a11yProps, TabPanel } from '../../components/TabPanel'
import { IFEvent } from '../../types'
import { getBaseFEvent } from '../../helpers/fevent';
import { prepareUrlWithId } from '../../helpers'
import { Update } from '@mui/icons-material'

import checkmarkIcon from '../../images/checkmark_icon.png'
import DateTimePicker from '../../components/DateTimePicker'
import { ETimezones } from '../../configs/timezones'
import ConfirmButton from '../../components/ConfirmButton'

const propsToHide: string[] = [
    'createdBy', 'updatedBy', 'confirmedBy', 'countryCodes', 'baseTimezone',
]
const propsToEdit: string[] = [
    'name', 'shouldCompletedBefore', 'timezone',
    'status', 'additionalInfo', 'isCompletedMarker', 'remarks',
]

const ItemInfoCard = (props: { item: Partial<IFEvent> }) => {
    const { item } = props
    const navigate = useNavigate()

    const [itemToUpdate, setItemToUpdate] = useState<Pick<
        IFEvent,
        'status' | 'additionalInfo' | 'remarks' | 'isCompletedMarker' | 'name' | 'shouldCompletedBefore' | 'timezone'
    >>({
        name: item.name!,
        shouldCompletedBefore: item.shouldCompletedBefore!,
        timezone: item.timezone!,
        status: item.status,
        additionalInfo: item.additionalInfo,
        remarks: item.remarks,
        isCompletedMarker: item.isCompletedMarker!,
    })

    const feventUpdateSubmit = () => {
        requestHelper.request({
            method: 'patch',
            url: prepareUrlWithId(EApiRoutes.adminUpdateFEventById, item.id!),
            data: itemToUpdate
        }).then(() => navigate(0))
    }
    const feventCompleteSubmit = () => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminCompleteFEventAsAuthor, item.id!),
        }).then(() => navigate(0))
    }

    return (
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <Typography variant='h5'>
                {item.username}
            </Typography> */}
            <br /><br />
            <TableContainer component={Paper} style={{ width: '50%' }}>
                <Table>
                    <TableBody>
                        {
                            Object.keys(item).filter(p => !propsToHide.includes(p)).map((prop: string, i) => {
                                let tableCellWithValue = <TableCell align='right'>{
                                    Array.isArray((item as any)[prop])
                                        ? (item as any)[prop].join(',')
                                        : (item as any)[prop]?.toString()
                                }</TableCell>

                                if (propsToEdit.includes(prop) && ![
                                    EFEventStatuses.completed, EFEventStatuses.canceled, EFEventStatuses.declined
                                ].includes(item.status!)) {
                                    tableCellWithValue = <TableCell align='right'>
                                        <FormControl>
                                            <InputLabel id={`${(texts[lang] as any)[prop]}-label`}>{(texts[lang] as any)[prop]}</InputLabel>
                                            <Input
                                                placeholder={(texts[lang] as any)[prop]}
                                                value={(itemToUpdate as any)[prop] || (item as any)[prop]}
                                                onChange={(e): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: ['tags'].includes(prop)
                                                        ? e.target.value.split(',')
                                                        : e.target.value
                                                })}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    if (['additionalInfo', 'isCompletedMarker', 'remarks'].includes(prop)) {
                                        tableCellWithValue = <TableCell align='right'>
                                            <TextField
                                                title={(texts[lang] as any)[prop]}
                                                placeholder={(texts[lang] as any)[prop]}
                                                value={(itemToUpdate as any)[prop]}
                                                multiline
                                                minRows={10}
                                                fullWidth
                                                onChange={(e): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: e.target.value
                                                })}
                                            />
                                        </TableCell>
                                    } else if (prop === 'status') {
                                        tableCellWithValue = <TableCell align='right'>
                                            {
                                                [EFEventStatuses.onReview, EFEventStatuses.holdForChecking].includes(item.status!)
                                                    ? (
                                                        <Select
                                                            placeholder='Status'
                                                            value={itemToUpdate.status}
                                                            onChange={(_, newValue): any => setItemToUpdate({
                                                                ...itemToUpdate,
                                                                status: newValue as EFEventStatuses
                                                            })}>
                                                            {
                                                                Object.values(EFEventStatuses).map((status: EFEventStatuses) => (
                                                                    <Option value={status}>{texts[lang][status]}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    ) : <div>{(texts[lang] as any)[item.status]}</div>
                                            }
                                            {[EFEventStatuses.running, EFEventStatuses.holdForChecking].includes(item.status) && (
                                                <ConfirmButton
                                                    title={<img style={{ width: '32px' }} src={checkmarkIcon} alt='confirm' />}
                                                    onConfirm={feventCompleteSubmit}
                                                />
                                            )}
                                        </TableCell>
                                    } else if (['name'].includes(prop)) {
                                        tableCellWithValue = <TableCell align='right'>
                                            <TextField
                                                title={(texts[lang] as any)[prop]}
                                                placeholder={(texts[lang] as any)[prop]}
                                                value={(itemToUpdate as any)[prop]}
                                                fullWidth
                                                onChange={(e): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: e.target.value
                                                })}
                                            />
                                        </TableCell>
                                    } else if (['shouldCompletedBefore'].includes(prop)) {
                                        tableCellWithValue = <TableCell align='right'>
                                            <DateTimePicker
                                                val={(itemToUpdate as any)[prop]}
                                                labelName={(texts[lang] as any)[prop]}
                                                onChange={(e: any): any => {
                                                    // console.log('e >> ', e, ' > ', e.toString());
                                                    return setItemToUpdate({
                                                        ...itemToUpdate,
                                                        [prop]: e.toString()
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                    } else if (['timezone'].includes(prop)) {
                                        tableCellWithValue = <TableCell align='right'>
                                            <Autocomplete
                                                options={
                                                    prop === 'timezone'
                                                        ? Object.values(ETimezones)
                                                        : []
                                                } // Преобразуем enum в массив значений
                                                value={(itemToUpdate as any)[prop]}
                                                onChange={(e, newValue): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: newValue as any
                                                })}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={(texts[lang] as any)[prop]} variant='outlined' />
                                                )}
                                                fullWidth
                                            />
                                        </TableCell>
                                    } else if ([''].includes(prop)) {
                                        tableCellWithValue = <TableCell align='right'>
                                            <TextField
                                                title={(texts[lang] as any)[prop]}
                                                type='number'
                                                placeholder={(texts[lang] as any)[prop]}
                                                value={(itemToUpdate as any)[prop]}
                                                fullWidth
                                                slotProps={{
                                                    htmlInput: {
                                                        step: 0.1,
                                                        min: 0.1
                                                    }
                                                }}
                                                onChange={(e): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: +e.target.value
                                                })}
                                            />
                                        </TableCell>
                                    }
                                }

                                if (prop === 'platforms') {
                                    tableCellWithValue = <TableCell align='right'>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', }}>
                                            {/* {item.platforms?.map((option, i) => {
                                                return <div>{i}: {option}</div>
                                            })} */}
                                            {
                                                Object.keys(item.platforms || {}).map((p, j) => {
                                                    return (
                                                        <>
                                                            {p}: {(item.platforms as any)[p].published ? 'Опубликовано' : 'Нет'}
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </TableCell>
                                } else if (prop === 'createdByUser') {
                                    tableCellWithValue = (
                                        <TableCell align='right'>
                                            <Link to={prepareUrlWithId(ERoutes.adminManageUser, item.createdByUser?.id!)}>{item.createdByUser?.username}</Link>
                                        </TableCell>
                                    )
                                } else if (prop === 'actions') {
                                    tableCellWithValue = (
                                        <TableCell align='right'>
                                            {
                                                item.actions!.map(action => (
                                                    <p>
                                                        {(texts[lang] as any)[action.action]}<br />
                                                        {action.createdAt.toString()}<br />
                                                        <Link to={prepareUrlWithId(ERoutes.adminManageUser, action.user.id)}>{action.user.username}</Link>
                                                    </p>
                                                ))
                                            }
                                        </TableCell>
                                    )
                                }

                                return (
                                    <TableRow key={i}>
                                        <TableCell align='right'>{(texts[lang] as any)[prop] || prop}</TableCell>
                                        {tableCellWithValue}
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell align='right'>
                                {/* {
                                    Object.keys(itemToUpdate).some(k => (itemToUpdate as any)[k] !== (item as any)[k])
                                        ? <Button type='button' onClick={() => feventUpdateSubmit()}><Update color='primary' /></Button>
                                        : ''
                                } */}
                                {
                                    Object.keys(itemToUpdate).some(k => (itemToUpdate as any)[k] !== (item as any)[k])
                                        ? <ConfirmButton
                                            title={<Update color='primary' />}
                                            onConfirm={feventUpdateSubmit}
                                            confirmationText={`${texts[lang].yes}, ${texts[lang].statusOnUpdateIs}: ${itemToUpdate.status}`}
                                        />
                                        : ''
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    )
}

export const FEvent = () => {
    const { id } = useParams()
    const [itemData, updateItemData] = useState<Partial<IFEvent>>({
        ...getBaseFEvent()
    })
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (!itemData?.id) {
        requestHelper.request({
            method: 'get',
            url: prepareUrlWithId(EApiRoutes.feventById, id?.toString()!),
        }).then((data) => updateItemData(data))
    }

    return itemData?.id ? (
        <Container>
            <Typography variant='h5' component='div' textAlign='center'>{itemData.name}</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="login-register">
                    <Tab label={texts[lang].information} {...a11yProps(0)} />
                    {/* <Tab label={texts[lang].votes} {...a11yProps(1)} /> */}
                    {/* <Tab label={texts[lang].transactions} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ItemInfoCard item={itemData} />
            </TabPanel>
            {/* <TabPanel value={tabValue} index={1}>
                <EventPollVotes item={itemData as IEventPoll} />
            </TabPanel> */}
            {/* <TabPanel value={tabValue} index={2}>
                <EventPollTransactions eventPoll={itemData as IEventPoll} />
            </TabPanel> */}
        </Container>
    ) : <Loader />
}
