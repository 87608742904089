import { DEFAULT_GRID_ITEMS_COUNT, EApiRoutes, EFEventStatuses, ERoutes, lang } from '../../configs/constants'
import { useEffect, useRef, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, Input, Table, TableRow, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { Edit, Save } from '@mui/icons-material'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { NavigateFunction, useNavigate } from 'react-router'
import { IFEvent } from '../../types'
import { CreateFEventPopper } from './FEventPoppers'
import texts from '../../configs/texts'

const EntityRow = (props: {
    itemKey: number
    item: IFEvent
    onUpdateSubmit: Function
    navigate: NavigateFunction
}) => {
    const { item, onUpdateSubmit } = props

    const [updatedItem,] = useState<Pick<IFEvent, 'id' | 'status'>>({
        id: item.id,
        status: item.status,
    });

    return (
        <TableRow style={{
            background: props.itemKey % 2 ? 'rgb(162,162,208, 0.1)' : 'white',
        }}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.status}</td>
            <td>{item.rewardAmount}</td>
            <td>{item.remarks}</td>
            <td>{item.additionalInfo}</td>
            <td>{item.isCompletedMarker}</td>
            <td>{item.shouldCompletedBefore.toString()}</td>
            <td>{item.createdAt?.toString()}</td>
            <td>{item.createdBy}</td>
            <td>{item.timezone}</td>
            <td>
                {
                    !(
                        item.status?.toString() === updatedItem.status?.toString()
                    ) && (
                        <Button
                            onClick={() => onUpdateSubmit(updatedItem)}
                        ><Save color='success' /></Button>
                    )
                }
                {
                    item.status !== EFEventStatuses.completed && (
                        <Button
                            onClick={() => props.navigate(prepareUrlWithId(ERoutes.adminManageFEvent, item.id!))}
                        ><Edit color='info' /></Button>
                    )
                }
            </td>
        </TableRow>
    )
}

export const ManageEntities = () => {
    const [entities, entitiesUpdate] = useState<{ count: number, list: IFEvent[] }>({ count: 0, list: [] });
    // const [visibleEntities, setVisibleEntities] = useState<IEventPoll[]>([]);
    const [filter, filterUpdate] = useState<Pick<IFEvent, 'status'>>({} as any)
    const [itemsCount, setItemsCount] = useState<number>(DEFAULT_GRID_ITEMS_COUNT);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const loadMore = () => {
        setItemsCount(itemsCount + DEFAULT_GRID_ITEMS_COUNT);
    };

    useEffect(() => {
        const loadMoreItems = async () => {
            const { count, list } = await getEntitiesList();
            entitiesUpdate({ count, list: [...entities.list, ...list] as IFEvent[] });
        };

        if (itemsCount > 0) {
            loadMoreItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount]);

    useEffect(() => {
        setItemsCount(DEFAULT_GRID_ITEMS_COUNT);

        const loadMoreItems = async () => {
            const { count, list } = await getEntitiesList();
            entitiesUpdate({ count, list: list as IFEvent[] });
        };

        loadMoreItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const getEntitiesList = async () => {
        return requestHelper.request<true>({
            method: 'get',
            url: EApiRoutes.fevent,
            params: {
                skip: itemsCount - DEFAULT_GRID_ITEMS_COUNT,
                limit: DEFAULT_GRID_ITEMS_COUNT,
                filter: JSON.stringify(filter)
            }
        }, { isListResponse: true })
        // if (eventPolls.list.length <= itemsCount - DEFAULT_GRID_ITEMS_COUNT) {

        // }
    }

    const navigate = useNavigate()
    const onSaveSubmit = async (item: Partial<IFEvent>) => {
        const { id, ...preparedItem } = item
        const preparedParams: AxiosRequestConfig = id
            ? {
                method: 'patch',
                url: prepareUrlWithId(EApiRoutes.adminUpdateFEventById, id),
                data: preparedItem
            } : {
                method: 'post',
                url: EApiRoutes.fevent,
                data: preparedItem,
            }

        requestHelper.request(preparedParams).then(() => {
            getEntitiesList()
        })
    }

    if (!entities.count) {
        getEntitiesList()
    }

    return (
        <div>
            <Typography variant='h6'>События</Typography>
            <CreateFEventPopper onSaveSubmit={onSaveSubmit} />
            < br /><br />
            <Input
                placeholder='Filter by status...'
                value={filter.status}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    status: e.target.value
                } : {} as any)}
            />
            {
                entities.list?.length
                    ? (
                        <>
                            <Table aria-label="basic table">
                                <thead>
                                    <tr>
                                        <td>id</td>
                                        <td>{texts[lang].name}</td>
                                        <td>{texts[lang].status}</td>
                                        <td>{texts[lang].rewardAmount}</td>
                                        <td>{texts[lang].remarks}</td>
                                        <td>{texts[lang].additionalInfo}</td>
                                        <td>{texts[lang].isCompletedMarker}</td>
                                        <td>{texts[lang].shouldCompletedBefore}</td>
                                        <td>{texts[lang].createdAt}</td>
                                        <td>{texts[lang].createdBy}</td>
                                        <td>{texts[lang].timezone}</td>
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        entities.list?.map((item, i) => (
                                            <EntityRow
                                                key={i}
                                                itemKey={i}
                                                item={item}
                                                navigate={navigate}
                                                onUpdateSubmit={onSaveSubmit}
                                            />
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <div ref={loadMoreRef} style={{ height: '20px', marginTop: '16px' }}>
                                {entities.list.length < entities.count && (
                                    <Button style={{ padding: '5px' }} color='info' onClick={loadMore}>
                                        ...
                                    </Button>
                                )}
                            </div>
                        </>
                    )
                    : <Loader />
            }
        </div >
    )
}
