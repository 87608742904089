module.exports = {
    ru: {
        clubNameValue: 'Ярмарка Событий',
        welcomeToClub: 'Добро пожаловать в клуб',
        aboutUs: 'О нас',
        confirmationOfAction: 'Подтверждение действия',
        wiki: 'Вики',
        information: 'Информация',
        fairOfEvents: 'Ярмарка событий',
        authorizationRequired: 'Необходима авторизация',
        makeAuthorization: 'Авторизуйтесь',
        main: 'Главная',
        login: 'Войти',
        logout: 'Выйти',
        id: 'ИД',
        register: 'Зарегистрироваться',
        registerUnaccessible: 'Регистрация не доступна.',
        registerForTakeParticipation: 'Зарегистрируйтесь чтобы принять участие',
        takeParticipation: 'Принять участие',
        registeredWithRequiredEmailConfirmation: 'Зарегистрированы. На почту отправлено письмо с кодом подтверждения.',
        successEmailConfirmationAndAuthorized: 'Почта подтверждена. Пользователь авторизован.',
        invalidEmailConfirmation: 'Ошибка при подтверждении почты.',
        send: 'Отправить',
        successful: 'Успешно',
        firstName: 'Имя',
        lastName: 'Фамилия',
        username: 'Логин',
        uniqueUsername: 'Уникальный логин',
        usernameAsEmail: 'Логин (почта)',
        email: 'Почта',
        phone: 'Телефон',
        name: 'Имя',
        password: 'Пароль',
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтверждение пароля',
        additionalData: 'Дополнительные данные',
        balance: 'Баланс',
        balanceBefore: 'Баланс до',
        balanceAfter: 'Баланс после',
        socNetworkPageForExample: 'Например страница в соцсети',
        country: 'Страна',
        city: 'Город',
        result: 'Результат',
        welcome: 'Приветствуем',
        createdAt: 'Дата создания',
        updatedAt: 'Дата обновления',
        updatedBy: 'Обновлено пользователем',
        createdBy: 'Автор',
        createdByUser: 'Автор',
        actions: 'Действия',
        eventDescription: 'Описание',
        remarks: 'Заметки',
        additionalInfo: 'Дополнительная информация',
        creator: 'Создатель',
        status: 'Статус',
        statusOfEvent: 'Статус события',
        state: 'Состояние',
        role: 'Роль',
        hold: 'В ожидании',
        inactive: 'Не активен',
        active: 'Активен',
        add: 'Добавить',
        action: 'Действие',
        confirm: 'Подтвердить',
        title: 'Название',
        details: 'Подробности',
        eventDate: 'Дата события',
        createEvent: 'Создать событие',
        planned: 'Запланировано',
        canceled: 'Закрыто',
        no: 'Нет',
        yes: 'Да',
        completed: 'Выполнено',
        startType: 'Начните ввод...',
        markAsCompleted: 'Отметить выполнение',
        transactions: 'Транзакции',
        fevents: 'События',
        wayOfResultConfirmation: 'Способ подтверждения',
        datetimeOfConfirmation: 'Дата и время подтверждения',
        initialBankAmount: 'Начальный банк',
        deposit: 'Депозит',
        withdrawal: 'Вывод',
        transferType: 'Тип трансфера',
        externalCurrency: 'Внешняя валюта',
        amount: 'Сумма',
        amountInBank: 'Сумма в банке',
        bank: 'Банк',
        amountToVote: 'Сумма взноса при участии',
        comissionPercent: '% комиссии',
        orgPercent: '% организатора',
        options: 'Варианты',
        countedAfterComplete: 'Посчитано',
        confirmedBy: 'Подтверждено',
        confirmedAt: 'Подтверждено в',
        currentAmount: 'Текущая сумма',
        countryCodes: 'Коды стран',
        minParticipantsCount: 'Минимальное количество участников',
        currentParticipantsCount: 'Текущее количество участников',
        date: 'Дата',
        selectedOption: 'Выбранный вариант',
        eventPoll: 'Событие-Опрос',
        invalidAuthorization: 'Ошибка авторизации',
        invalidValue: 'Неправильное значение',
        invalidSolution: 'Неправильное решение',
        commonInformation: 'Общая информация',
        payouts: 'Выплаты',
        makePayout: 'Выплатить',
        paid: 'Выплачено',
        platforms: 'Платформы',
        picture: 'Изображение',
        tags: 'Тэги',
        maxVotes: 'Максимум участников',
        payoutDetails: 'Подробности',
        payoutScreenshot: 'Путь к скриншоту',
        faq: 'Вопросы и ответы',
        userTerms: 'Пользовательское соглашение',
        promotions: 'Промоакции',
        levelAndRewards: 'Уровни и Вознаграждения',
        level: 'Виток',
        rewards: 'Вознаграждения',
        infoIsAvailableForRegisteredUsers: 'Информация доступна участникам Клуба.',
        tableOfLevelAndRewards: 'Таблица витков и вознаграждений',
        betOnEvents: 'Ставки на События',
        betOnEventsDescription: 'Участвуйте в платных опросах и делайте ставки на будущее! Угадывайте события и выигрывайте.',
        moreDetails: 'Подробнее',
        loyalSystemOfRewards: 'Лояльная система бонусов',
        loyalSystemOfRewardsDescription: 'Поднимайтесь по уровням участвуя в опросах чтобы получить доступ к интересным бонусам.',
        earnMoneyInRefSystem: 'Зарабатывайте в нашей реферальной системе',
        earnMoneyInRefSystemDescription: 'Приглашайте друзей и знакомых чтобы принять участие в реферальной системе.',
        initYourOwnEvent: 'Управляй',
        initYourOwnEventForBetAndEarnMoneyDescription: 'Предложи собственное событие и получи до 10% от общего банка ставок (предложение является частью системы вознаграждения).',
        beOneStepAhead: 'Будьте на шаг впереди новостей.',
        beOneStepAheadDescription: 'Предвосхищайте события до их появления в новостных лентах.',
        joinUs: 'Присоединяйтесь к нам!',
        takeParticipationOnPollAndImproveQualityOfYourLife: 'Участвуйте в наших опросах и улучшайте качество Вашей жизни.',
        getMore: 'Ещё',
        isCompletedMarker: 'Маркер выполнения',
        shouldCompletedBefore: 'Срок выполнения',
        onReview: 'На рассмотрении',
        waitingForPerformer: 'Ожидает исполнителя',
        onPerformerReview: 'На рассмотрении исполнителем',
        running: 'Выполняется',
        holdForChecking: 'В ожидании рассмотрения',
        declined: 'Отклонено',
        timezone: 'Часовой пояс',
        rewardAmount: 'Вознаграждение',
        seeDetails: 'Ознакомиться',
        startReview: 'Ознакомиться',
        takeToPerform: 'Взять к выполнению',
        rejectPerform: 'Отказаться от выполнения',
        confirmExecutionAsPerformer: 'Подтвердить выполнение как исполнитель',
        confirmExecutionAsAuthor: 'Подтвердить выполнение как заказчик',
        cancelAsAuthor: 'Отменить как заказчик',
        statusOnUpdateIs: 'Статус при обновлении',
    }
}
