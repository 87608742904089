import { DateTime } from 'luxon';
import { IFEvent } from '../types';

export const getBaseFEvent: () => Partial<IFEvent> = () => ({
    name: '',
    rewardAmount: 0.1,
    remarks: '',
    additionalInfo: '',
    shouldCompletedBefore: DateTime.now().plus({ days: 5 }).toJSDate(),
})
