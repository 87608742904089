import * as React from 'react';
import { Link, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Box, Card, Container, Tab, Tabs } from '@mui/material';
import texts from '../../configs/texts';
import { EApiRoutes, ERoutes, EUSerRoles, lang, storageKeys } from '../../configs/constants';
import { IUserInfo } from '../../types';
import { requestHelper } from '../../helpers/requestHelper';
import { Loader } from '../../components/Loader';
import { prepareUrlWithId } from '../../helpers';
import { ChangePasswordModal, TransferRequestModal } from './UserModals';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import voteIcon from '../../images/vote_icon.png'
import transactionIcon from '../../images/transaction_icon.png'
import { UserTransactions } from './UserTransactions';
// import { ISeriesOfEvent } from '../../types/';

export const UserInfo = (props: {
    onAuthorize: React.Dispatch<React.SetStateAction<string>>
}) => {
    const [user, userUpdate] = React.useState<IUserInfo>(null as any)
    let { id } = useParams()

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getUser = async () => {
        requestHelper.request({
            method: 'get',
            url: prepareUrlWithId(EApiRoutes.userById, +(id as string))
        }).then((user: IUserInfo) => {
            userUpdate(user)
        })
    }

    const changePasswordSubmit = async (data: { old: string, password: string }) => {
        return requestHelper.request({
            method: 'patch',
            url: prepareUrlWithId(EApiRoutes.userById, user.id!),
            data
        })
    }
    const transferRequestSubmit = async (data: any) => {
        return requestHelper.request({
            method: 'post',
            url: EApiRoutes.transferRequest,
            data
        })
    }
    const logout = () => {
        localStorage.removeItem(storageKeys.authToken)
        localStorage.removeItem(storageKeys.userInfo)
        props.onAuthorize('')
    }

    if (!user && !isNaN(id as any)) getUser()

    return user ? (
        <Container>
            <Card style={{ width: '100%' }}>
                <div className='user-info-container'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar />
                        <p>{texts[lang].welcome} {user.firstName} {user.lastName}!</p>
                        {user.username}
                        <p>
                            {texts[lang].balance}: {user.wallet.balance}
                        </p>
                        {
                            user.role === EUSerRoles.admin && (
                                <Typography textAlign='center'>
                                    <Link to={ERoutes.admin}>Admin</Link>
                                </Typography>
                            )
                        }
                        <div className='user-actions'>
                            <Typography textAlign='center'>
                                <ChangePasswordModal onSaveSubmit={changePasswordSubmit} />
                            </Typography>
                            <Typography textAlign='center'>
                                <TransferRequestModal onSaveSubmit={transferRequestSubmit} />
                            </Typography>
                        </div>
                    </div>
                    <Button color='inherit' onClick={logout}><LogoutIcon /></Button>
                </div>
                <div className='user-actions'>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                variant='scrollable'
                                scrollButtons='auto'
                                value={value}
                                onChange={handleChange}
                                aria-label='basic tabs example'
                            >
                                <Tab label={<img src={voteIcon} width='32px' alt='alt tab vote' />} {...a11yProps(0)} />
                                <Tab label={<img src={transactionIcon} width='32px' alt='alt tab txn' />} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            {/* <UserVotes user={user} /> */}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <UserTransactions user={user} />
                        </TabPanel>
                    </Box>
                </div>
                <div className=''>
                    <Typography textAlign='center' variant='h6'>
                        {/* {texts[lang].solvedSeries} ({user.solvedSeries?.length}) */}
                    </Typography>
                    {/* <SeriesGrid seriesOfEvents={user.solvedSeries as any[]} activeSeriesChange={null as any} linkMode={true} /> */}
                </div>
            </Card>
        </Container>
    ) : <Loader />
}
