import { useEffect, useRef, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { BASE_CURRENCY_UNICODE, DEFAULT_DATE_FORMAT, DEFAULT_GRID_ITEMS_COUNT, EApiRoutes, EFEventStatuses, lang, TG_PLATFORM_CH_URL } from '../../configs/constants'
import { Loader } from '../../components/Loader'
import { IFEvent } from '../../types'
import {
    Card,
    CardContent,
    Grid,
    Grid2,
    Typography
} from '@mui/material'
import Button from '@mui/material/Button';
import texts from '../../configs/texts'
import "react-multi-carousel/lib/styles.css";
import TitleValue from '../../components/TitleValue'
import { DateTime } from 'luxon'
import { Link } from 'react-router'

// const responsive = {
//     superLargeDesktop: {
//         // the naming can be any, depends on you.
//         breakpoint: { max: 4000, min: 3000 },
//         items: 5
//     },
//     desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 3
//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 464 },
//         items: 2
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 1
//     }
// };

// const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
//     const { carouselState: { currentSlide } } = rest;
//     return (
//         <div
//             className="carousel-button-group"
//             style={{
//                 position: 'absolute',
//                 width: '100%',
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 bottom: '-1%',
//                 background: 'yellow',
//             }}
//         >
//             <Button
//                 className={currentSlide === 0 ? 'disable' : ''}
//                 onClick={() => previous()}
//                 style={{ fontSize: '24px' }}
//             >
//                 {'<'}
//             </Button>
//             {/* <Button onClick={() => next()}>B</Button> */}
//             <Button
//                 onClick={() => goToSlide(currentSlide + 1)}
//                 style={{ fontSize: '24px' }}
//             >{'>'}</Button>
//         </div>
//     );
// };

const EntityGridItem = (props: {
    entity: IFEvent,
}) => {
    const { entity } = props

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: entity.name,
        startDate: (entity.updatedAt || entity.createdAt!).toString(),
        endDate: entity.shouldCompletedBefore.toString(),
        location: 'web',
        keywords: entity.name.split(' ')
    }

    return (
        <Grid2 sx={{ p: 2, }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
                sx={{
                    position: 'relative',
                    width: 250, // фиксированная ширина
                    height: 306, // фиксированная высота
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px'
                    // '&:before': {
                    //     content: '"image"',
                    //     position: 'absolute',
                    //     top: 0,
                    //     left: 0,
                    //     width: '100%',
                    //     height: '100%',
                    //     // backgroundImage: `url(${item.picture})`,
                    //     backgroundSize: 'cover',
                    //     backgroundPosition: 'center',
                    //     opacity: 0.1
                    // },
                }}
            >
                <Typography variant="h6" component="div" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {entity.name}
                </Typography>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        // textAlign: 'center',
                        flexGrow: 1,
                        gap: '10px'
                    }}
                >
                    <TitleValue columnDirection title={texts[lang].rewardAmount} value={entity.rewardAmount + BASE_CURRENCY_UNICODE} />
                    <TitleValue
                        columnDirection
                        title={texts[lang].shouldCompletedBefore}
                        value={`${DateTime.fromJSDate(new Date(entity.shouldCompletedBefore)).setLocale(lang).toFormat(DEFAULT_DATE_FORMAT)
                            } ${entity.timezone}`}
                    />
                    {/* <TitleValue columnDirection title={texts[lang].status} value={(texts[lang] as any)[entity.status]} /> */}
                    {entity.status === EFEventStatuses.waitingForPerformer && (
                        <Button color="success" component={Link} to={TG_PLATFORM_CH_URL} style={{ background: 'green', color: 'white', borderRadius: '5px' }}>
                            {texts[lang].seeDetails}
                        </Button>
                    )}
                </CardContent>
            </Card>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Grid2>
    )
}

export const FEventsGrid = () => {
    const [entities, entitiesUpdate] = useState<{ count: number, list: IFEvent[] }>({ count: 0, list: [] });
    const [itemsCount, setItemsCount] = useState<number>(DEFAULT_GRID_ITEMS_COUNT);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const loadMore = () => {
        setItemsCount(itemsCount + DEFAULT_GRID_ITEMS_COUNT);
    };

    useEffect(() => {
        const loadEntities = () => {
            getEntitiesList();
        };
        loadEntities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount]);

    const getEntitiesList = async () => {
        if (entities.list.length <= itemsCount - DEFAULT_GRID_ITEMS_COUNT) {
            requestHelper.request<true>({
                method: 'get',
                url: EApiRoutes.fevent,
                params: {
                    skip: itemsCount - DEFAULT_GRID_ITEMS_COUNT,
                    limit: DEFAULT_GRID_ITEMS_COUNT
                }
            }, { isListResponse: true }).then(({ count, list }) => {
                entitiesUpdate({ count, list: [...entities.list, ...list] as IFEvent[] });
            })
        }
    }

    if (!entities.count) {
        getEntitiesList()
    }

    return entities.list?.length ? (
        <>
            <Grid container spacing={3} style={{ justifyContent: 'center' }}>
                {entities.list.map((entity, index) => (
                    <EntityGridItem entity={entity} key={index} />
                ))}
            </Grid>
            <div ref={loadMoreRef} style={{ height: '20px', marginTop: '16px' }}>
                {entities.list.length < entities.count && (
                    <Button style={{ padding: '5px' }} color='info' onClick={loadMore}>
                        ...
                    </Button>
                )}
            </div>
        </>
    ) : <Loader />
}
