import React from 'react';
import { Box, Typography } from '@mui/material';

interface TitleValueProps {
    title: string;
    value: React.ReactNode;
    columnDirection?: boolean
}

const TitleValue: React.FC<TitleValueProps> = ({ title, value, columnDirection }) => {
    return (
        <Box display='flex' gap={1} flexDirection={columnDirection ? 'column' : 'row'} >
            <Typography variant='body1' fontWeight='bold' component='span'>
                {title}:
            </Typography>
            <Typography variant='body1' component='span'>
                {value}
            </Typography>
        </Box>
    );
};

export default TitleValue;
