import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import './styles/App.scss';
import './styles/styles.scss';
import { AuthProvider } from './ctx/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { INotificationProps, IUserInfo } from './types';
import { requestHelper } from './helpers/requestHelper';
import { deleteUserData, getAuthToken } from './helpers';
import { EApiRoutes, ERoutes, EUserStatuses } from './configs/constants';
import { hookHelper } from './helpers/hookHelper';
import { Notification } from './components/Notification';
import { AdminApp } from './Admin/AdminApp';
import { User } from './Admin/Users/User';
import { Authorize } from './containers/Authorize';
import { UserInfo } from './containers/user/UserInfo';
import { FEvent } from './Admin/FEvents/FEvent';
import { routes } from './routes';

const App: React.FC = () => {
    const [notification, notificationUpdate] = useState({
        notificationType: null as any,
        message: ''
    } as INotificationProps)

    requestHelper.init({ initNotification: notificationUpdate })

    const [authorized, updateAuthorized] = useState(getAuthToken())
    const [userInfo, userInfoUpdate]: [IUserInfo, React.Dispatch<React.SetStateAction<IUserInfo>>] = useState({
        id: 0,
        status: EUserStatuses.active,
    } as IUserInfo)

    if (!userInfo?.id && authorized) {
        requestHelper.request({
            method: 'post',
            url: EApiRoutes.userAuth
        }).then((data: IUserInfo) => {
            if (data?.id) {

                userInfoUpdate(data);
            } else {
                deleteUserData();
                updateAuthorized('')
            }
        })
    }

    hookHelper.init({ notificationUpdate })

    return (
        <AuthProvider>
            <Router>
                {
                    typeof notification.notificationType === 'number'
                        ? <Notification
                            notificationType={notification.notificationType}
                            message={notification.message}
                            unsetNotification={notificationUpdate}
                        />
                        : ''
                }
                {/* <CssBaseline /> */}
                {/* <Header /> */}
                <Navbar />
                <Container className='App' style={{ minHeight: '90vh' }}>
                    <Routes>
                        {
                            routes.map(r => (
                                <Route path={r.path} element={r.element as any}></Route>
                            ))
                        }
                        <Route
                                path={ERoutes.user}
                                element={<Authorize authorized={authorized}
                                    onAuthorize={updateAuthorized}
                                    notificationUpdate={notificationUpdate}
                                >
                                    <UserInfo onAuthorize={updateAuthorized} />
                                </Authorize>}
                            ></Route>
                        <Route
                            path={ERoutes.admin}
                            element={
                                <PrivateRoute>
                                    <AdminApp />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ERoutes.adminManageUser}
                            element={
                                <PrivateRoute>
                                    <User />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ERoutes.adminManageFEvent}
                            element={
                                <PrivateRoute>
                                    <FEvent />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </Container>
                <Footer />
            </Router>
        </AuthProvider>
    );
}

export default App;
