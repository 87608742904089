import {
    Box, CardContent, Container, Paper, Tab,
    Table, TableBody, TableCell, TableContainer, TableRow,
    Tabs, Typography
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import texts from '../../configs/texts'
import { EApiRoutes, lang } from '../../configs/constants'
import React, { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Loader } from '../../components/Loader'
import { a11yProps, TabPanel } from '../../components/TabPanel'
import { IUserInfo, IUserInfoFromAdmin } from '../../types'
import { getBaseUser, prepareUrlWithId } from '../../helpers'
import { ChangePasswordPopper, ITransferRequestData, TransferPopper } from '../Poppers'
import { UserTransactions } from './UserTransactions'
import { UserFEvents } from './UserFEvents'

const ItemInfoCard = (props: { item: Partial<IUserInfoFromAdmin> }) => {
    const { item } = props
    const navigate = useNavigate()

    const submitUpdateBalance = (data: ITransferRequestData) => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminTransfer, item.wallet!.id),
            data
        }).then(() => navigate(0))
    }

    const submitUpdatePassword = (data: { password: string }) => {
        requestHelper.request({
            method: 'patch',
            url: prepareUrlWithId(EApiRoutes.userById, item.id!),
            data: {
                password: data.password
            }
        }).then(() => navigate(0))
    }

    return (
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <Typography variant='h5'>
                {item.username}
            </Typography> */}
            <br /><br />
            <TableContainer component={Paper} style={{ width: '50%' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align='right'>ID</TableCell>
                            <TableCell align='right'>{item.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].firstName}</TableCell>
                            <TableCell align='right'>{item.firstName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].lastName}</TableCell>
                            <TableCell align='right'>{item.lastName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].username}</TableCell>
                            <TableCell align='right'>{item.username}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].email}</TableCell>
                            <TableCell align='right'>{item.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].phone}</TableCell>
                            <TableCell align='right'>{item.phone}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].createdAt}</TableCell>
                            <TableCell align='right'>{item.createdAt}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].role}</TableCell>
                            <TableCell align='right'>{item.role}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].status}</TableCell>
                            <TableCell align='right'>{item.status}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].additionalData}</TableCell>
                            <TableCell align='right'>{item.dataForVerify}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].balance}</TableCell>
                            <TableCell align='right' style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <TransferPopper userId={item.id!} submitUpdateBalance={submitUpdateBalance} />
                                <div>{item.wallet?.balance}</div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].password}</TableCell>
                            <TableCell align='right'>
                                <ChangePasswordPopper userId={item.id!} submitUpdate={submitUpdatePassword} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    )
}

export const User = () => {
    const { id } = useParams()
    const [itemData, updateItemData] = useState<Partial<IUserInfoFromAdmin>>({
        ...getBaseUser()
    })
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (!itemData?.id) {
        requestHelper.request({
            method: 'get',
            url: EApiRoutes.userById.replace(':id', id as string),
        }).then((data) => updateItemData(data))
    }

    return itemData?.id ? (
        <Container>
            <Typography variant='h5' component='div' textAlign='center'>{itemData.username}</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="login-register">
                    <Tab label={texts[lang].information} {...a11yProps(0)} />
                    <Tab label={texts[lang].transactions} {...a11yProps(1)} />
                    <Tab label={texts[lang].fevents} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ItemInfoCard item={itemData} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <UserTransactions user={itemData as IUserInfo} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <UserFEvents user={itemData as IUserInfo} />
            </TabPanel>
        </Container>
    ) : <Loader />
}
