import { ERoutes } from './configs/constants';
import { About } from './containers/About';
import { FEventsGrid } from './containers/fevents/FEventsListGrid';
import FaqPage from './containers/info/FAQ';
import { UserTermsPage } from './containers/info/Terms';

export const routes = [
    { path: ERoutes.main, element: <FEventsGrid /> },
    { path: ERoutes.about, element: <About /> },
    { path: ERoutes.faq, element: <FaqPage /> },
    { path: ERoutes.userTerms, element: <UserTermsPage /> },
]
