import { Box, Tab, Tabs } from '@mui/material';
import { a11yProps, TabPanel } from '../components/TabPanel';
import { useState } from 'react';
import { ManageUsers } from './Users/ManageUsers';
import { ManageEntities as ManageFEvents } from './FEvents/ManageFEvents';
import { Transactions } from './Transactions/Transactions';
import { Settings } from './Settings/Settings';

// https://reactrouter.com/en/main/components/outlet

export const AdminApp = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label='Пользователи' {...a11yProps(0)} />
                    <Tab label='События' {...a11yProps(1)} />
                    <Tab label='Транзакции' {...a11yProps(2)} />
                    <Tab label='Настройки' {...a11yProps(3)} />
                    <Tab label='Вознаграждения' {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ManageUsers />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ManageFEvents />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Transactions />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Settings />
            </TabPanel>
            <TabPanel value={value} index={4}>
                {/* <Rewards /> */}
            </TabPanel>
        </Box>
    )
}
