import { AppBar, Box, Button, Card, CardContent, Container, Grid, IconButton, Menu, MenuItem, Toolbar, Typography, useMediaQuery, } from '@mui/material';
import { Link } from 'react-router-dom';
import { ERoutes, lang } from '../configs/constants';
import texts from '../configs/texts';
import promoText from '../configs/promo'
import { useTheme } from '@mui/material/styles';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { features } from '../configs/features';

interface AboutNavProps {
    links: { label: string; href: string }[];
}
const aboutNavLinks: AboutNavProps = {
    links: [
        { label: texts[lang].userTerms, href: ERoutes.userTerms },
        { label: texts[lang].faq, href: ERoutes.faq },
    ]
}

export const AboutNav: React.FC<AboutNavProps> = ({ links }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" className='about-nav-bar'>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    {isMobile ? (
                        <>
                            <IconButton
                                edge="start"
                                // color="inherit"
                                aria-label="menu"
                                onClick={handleMenuOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {links.map((link) => (
                                    <MenuItem key={link.label} onClick={handleMenuClose}>
                                        <Button color="inherit" component={Link} to={link.href} fullWidth>
                                            {link.label}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    ) : (
                        <Box display="flex" justifyContent="space-between" width="100%">
                            {links.map((link) => (
                                <Button color="inherit" component={Link} to={link.href} fullWidth>
                                    {link.label}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};


const FeaturesSection = () => (
    <Grid container spacing={2}>
        {features.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
                <Card style={{ minHeight: '180px', display: 'flex', justifyContent: 'center' }}>
                    <CardContent>
                        <Typography variant="h5" component="div" textAlign='center' margin={1}>
                            {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" textAlign='justify'>
                            {item.description}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        ))}
    </Grid>
);

export function About() {
    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        'name': 'Ярмарка событий Промо',
        'description': 'Присоединяйтесь к Ярмарке событий и открывайте для себя новые виды заработка!',
        'mainEntity': {
            '@type': 'CreativeWork',
            'headline': 'Присоединяйтесь к Ярмарке событий!',
            'text': 'Представляем вам уникальную возможность объединить вашу компетентность с возможностью получать дополнительный доход...',
            'author': {
                '@type': 'Organization',
                'name': 'Ярмарка событий'
            },
            'publisher': {
                '@type': 'Organization',
                'name': 'Ярмарка событий'
            }
        }
    }

    return (
        <Box sx={{ padding: 2 }}>
            <AboutNav links={aboutNavLinks.links} />
            <br />
            <Typography variant='h3'>{texts[lang].clubNameValue}</Typography>
            <br />
            <FeaturesSection />
            <Box my={4} textAlign='justify'>
                <div dangerouslySetInnerHTML={{ __html: promoText[lang] }} />
            </Box>
            {/* <List>
                <ListItem button component={Link} to={ERoutes.faq}>
                    <ListItemText primary={texts[lang].faq} />
                </ListItem>
                <ListItem button component={Link} to={ERoutes.userTerms}>
                    <ListItemText primary={texts[lang].userTerms} />
                </ListItem>
                <ListItem button component={Link} to={ERoutes.levelAndRewards}>
                    <ListItemText primary={texts[lang].levelAndRewards} />
                </ListItem>
            </List> */}

            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Box>
    );
}
