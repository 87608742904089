import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import texts from '../configs/texts';
import { lang } from '../configs/constants';

interface IProps {
    onConfirm: any
    title: string | any
    confirmationText?: string
}

const ConfirmButton = (props: IProps) => {
    const [open, setOpen] = useState(false);

    // Открыть диалог
    const handleOpen = () => setOpen(true);

    // Закрыть диалог
    const handleClose = () => setOpen(false);

    // Подтвердить действие
    const handleConfirm = () => {
        props.onConfirm(); // Вызываем переданную функцию
        handleClose();
    };

    return (
        <>
            {/* Кнопка для вызова диалога */}
            <Button variant='contained' color='inherit' onClick={handleOpen}>
                {props.title}
            </Button>

            {/* Диалог подтверждения */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{texts[lang].confirmationOfAction} {props.title}</DialogTitle>
                {/* <DialogContent>
                    <DialogContentText>Вы уверены, что хотите выполнить это действие?</DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={handleConfirm} color='primary' autoFocus>
                        {props.confirmationText || texts[lang].yes}
                    </Button>
                    <Button onClick={handleClose} color='secondary'>
                        {texts[lang].no}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmButton;
