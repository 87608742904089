import React, { useState } from 'react';
import { IFEvent } from '../../types';
import { getBaseFEvent } from '../../helpers/fevent';
import addIcon from '../../images/add_icon.png'
import { Autocomplete, Box, Button, FormControl, FormGroup, Input, InputLabel, Popper, TextField } from '@mui/material';
import texts from '../../configs/texts';
import { ETargetPlatforms, lang } from '../../configs/constants';
import DateTimePicker from '../../components/DateTimePicker';
import { Save } from '@mui/icons-material';
import { ETimezones } from '../../configs/timezones';

export const CreateFEventPopper = (props: {
    onSaveSubmit: (item: Partial<IFEvent>) => Promise<void>
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newItem, newItemUpdate] = useState<Partial<IFEvent>>({ ...getBaseFEvent() })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onSave = () => {
        props.onSaveSubmit({ ...newItem }).then(() => {
            newItemUpdate({ ...getBaseFEvent() })
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <img style={{ width: '32px' }} src={addIcon} alt='confirm' />
            </button>
            <Popper
                id={id}
                open={open}
                placement='bottom'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <FormGroup className='create-form-group'>
                        <FormControl>
                            <InputLabel id={`${texts[lang].name}-label`}>{texts[lang].name}</InputLabel>
                            <Input
                                placeholder={texts[lang].name}
                                value={newItem.name}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    name: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <DateTimePicker
                                val={newItem.shouldCompletedBefore!}
                                labelName={texts[lang].shouldCompletedBefore}
                                onChange={(e: any): any => {
                                    // console.log('e >> ', e, ' > ', e.toString());
                                    return newItemUpdate({
                                        ...newItem,
                                        shouldCompletedBefore: e.toString()
                                    })
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={Object.values(ETimezones)} // Преобразуем enum в массив значений
                                value={newItem.timezone}
                                onChange={(e, newValue): any => newItemUpdate({
                                    ...newItem,
                                    timezone: newValue as ETimezones
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} label={texts[lang].timezone} variant='outlined' />
                                )}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].rewardAmount}-label`}>{texts[lang].rewardAmount}</InputLabel>
                            <TextField
                                title={texts[lang].rewardAmount}
                                type='number'
                                placeholder={texts[lang].rewardAmount}
                                value={newItem.rewardAmount}
                                fullWidth
                                slotProps={{
                                    htmlInput: {
                                        step: 0.1,
                                        min: 0.1
                                    }
                                }}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    rewardAmount: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                title='platforms'
                                options={Object.values(ETargetPlatforms)} // Преобразуем enum в массив значений
                                value={Object.keys(newItem.platforms || {})}
                                onChange={(e, newValue): any => newItemUpdate({
                                    ...newItem,
                                    platforms: Array.from(new Set(newValue))
                                        .reduce((acc: any, next: string) => {
                                            acc[next] = {}
                                            return acc
                                        }, {})
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} label={texts[lang].platforms} variant='outlined' />
                                )}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].additionalInfo}-label`}>{texts[lang].additionalInfo}</InputLabel>
                            <TextField
                                title={texts[lang].additionalInfo}
                                placeholder={texts[lang].additionalInfo}
                                value={newItem.additionalInfo}
                                multiline
                                minRows={10}
                                fullWidth
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    additionalInfo: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].isCompletedMarker}-label`}>{texts[lang].isCompletedMarker}</InputLabel>
                            <TextField
                                title={texts[lang].isCompletedMarker}
                                placeholder={texts[lang].isCompletedMarker}
                                value={newItem.isCompletedMarker}
                                multiline
                                minRows={10}
                                fullWidth
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    isCompletedMarker: e.target.value
                                })}
                            />
                        </FormControl>
                        {
                            (newItem.name) && (
                                <Button onClick={() => onSave()}><Save color='success' /></Button>
                            )
                        }
                    </FormGroup>
                </Box>
            </Popper>
        </div>
    );
}
