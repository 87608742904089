import { useLocation } from 'react-router';
import { ERoutes, MOMENT_HUMAN_DATE } from '../configs/constants';
import { useMemo } from 'react';
import { DateTime } from 'luxon';

export * from './user'

export const prepareUrlWithId = (url: any, id: number | string, propsWithValues?: { [propName: string]: string }): ERoutes => {
    let result: ERoutes = url

    if (id) result = url.replace(':id', id.toString())

    if (propsWithValues) {
        Object.entries(propsWithValues).forEach(([propName, propValue]) => {
            result = result.replace(`:${propName}`, propValue) as ERoutes
        })
    }

    return result
}

export const getNumsArray = (count: number): number[] => Array.from(Array(count)).map((_, i) => i)

export const useQuery = (): Record<string, any> => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const prepareDate = (src: Date, format = MOMENT_HUMAN_DATE) => DateTime.fromJSDate(src).toUTC().toFormat(format);
