export const TOTAL_COUNT_HEADER = 'x-total-count'

export const BASE_URL = 'https://eventsfair.live'
export const BASE_API_PATH = 'https://api.eventsfair.live/api'

export const EMAIL_REGEX = /^([A-Za-z0-9/.])+@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/

export const DEFAULT_GRID_ITEMS_COUNT = 8
export const DEFAULT_DATE_FORMAT = 'd MMMM yyyy, HH:mm'
export const BASE_CURRENCY_UNICODE = '🌀'

export enum EUserStatuses {
    pending = 'pending',
    active = 'active',
    inactive = 'inactive',
    disabledByReason = 'disabledByReason',
}

export enum EUSerRoles {
    admin = 'admin'
}

export enum ETargetPlatforms {
    telegram = 'telegram',
}

export const TG_PLATFORM_CH_URL = 'https://t.me/fair_of_events'

export enum ERoutes {
    user = '/user/:id',
    users = '/users',
    fevent = '/fevent/:id',
    main = '/',
    about = '/about',
    userVerify = '/user/:id/verify',

    faq = '/faq',
    userTerms = '/user-terms',

    admin = '/admin',
    adminManageUser = '/admin/user/:id',
    adminManageFEvent = '/admin/fevent/:id',
}


export enum EApiRoutes {
    config = '/settings/config',
    getDepositOptions = '/settings/get-deposit-options',

    user = '/user',
    userById = '/user/:id',
    userAuth = '/user/auth',

    fevent = '/fevent',
    feventById = '/fevent/:id',

    transferRequest = '/user-wallet/transfer-request',
    walletTransactions = '/user-wallet/transactions',

    externalPaymentAction = '/external-payment/:id/:action',

    dictCountries = '/dict/countries',
    dictFaq = '/dict/faq',
    dictTerms = '/dict/terms',

    adminTransfer = '/admin/user-wallet/:id/transfer',
    adminTransferConfirm = '/admin/user-wallet/transfer-confirm/:id', // id of transfer-transaction
    adminTransferCancel = '/admin/user-wallet/transfer-cancel/:id', // id of transfer-transaction
    adminBalanceInfo = '/admin/user-wallet/balance-info',
    adminUpdateSettings = '/admin/settings/:id/update',
    adminUpdateFEventById = '/admin/fevent/:id/update',
    adminCompleteFEventAsAuthor = '/admin/fevent/:id/complete-as-author',
}

export enum EFEventStatuses {
    onReview = 'onReview',
    waitingForPerformer = 'waitingForPerformer',
    onPerformerReview = 'onPerformerReview',
    running = 'running',
    holdForChecking = 'holdForChecking',
    completed = 'completed',
    declined = 'declined',
    canceled = 'canceled',
}

export const lang = 'ru'

export const MOMENT_HUMAN_DATE = 'D MMMM YYYY, HH:mm:ss'

export const storageKeys: {
    authToken: string
    userInfo: string
} = {
    authToken: 'authToken',
    userInfo: 'userInfo',
}

export enum ENotificationType {
    error,
    success,
    info,
    warning,
}

export enum ETransferTypes {
    deposit = 'deposit',
    withdrawal = 'withdrawal',
    depositRequest = 'depositRequest',
    withdrawalRequest = 'withdrawalRequest',
}

export enum EWalletTransactionStatuses {
    pending = 'pending',
    onReview = 'onReview',
    declined = 'declined',
    completed = 'completed',
    canceled = 'canceled',
}
